const middleware = {}

middleware['checkout'] = require('../_theme/middleware/checkout.js')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['is-authenticated'] = require('../_theme/middleware/is-authenticated.js')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['redirect'] = require('../_theme/middleware/redirect.ts')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
