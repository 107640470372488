import { integrationPlugin } from '@vue-storefront/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mapConfigToSetupObject } from '@vue-storefront/gemini/nuxt/helpers';
// eslint-disable-next-line import/no-extraneous-dependencies
import defaultConfig from '@vue-storefront/gemini/nuxt/defaultConfig';
// eslint-disable-next-line import/no-extraneous-dependencies
import cookie from '@vue-storefront/gemini/nuxt/cookie';

const moduleOptions = JSON.parse('{"i18n":{"useNuxtI18nConfig":true},"cookies":{"currencyCookieName":"vsf-currency","countryCookieName":"vsf-country","localeCookieName":"vsf-locale","cartCookieName":"vsf-cart","customerCookieName":"vsf-customer","storeCookieName":"vsf-store","messageCookieName":"vsf-message","segmentsCookieName":"vsf-segments"},"externalCheckout":{"enable":false,"cmsUrl":"https://api.internal.gogemini.io/graphql/9229817b-d960-4200-9bd1-3ff4b8fc857f","syncUrlPath":"/vue/cart/sync","stores":{"default":false}},"defaultStore":"default","facets":{"available":["colore","taglia"]},"shippyPro":{"apiKey":"e358468122db0585a3ea864a1e3d87e8"},"formspree":{"errorsEndpoint":"https://formspree.io/f/xqkobyan"},"storeUrlSecure":"https://www.lazzarionline.com","klaviyoCompanyId":"SSxr2i","klaviyoListId":"WTr9pw","locales":[{"name":"en"},{"name":"it"}]}');

export default integrationPlugin(({
  app, res, req, integration,
}) => {
  const cartCookieName = moduleOptions.cookies?.cartCookieName || defaultConfig.cookies.cartCookieName;
  const customerCookieName = moduleOptions.cookies?.customerCookieName || defaultConfig.cookies.customerCookieName;
  const storeCookieName = moduleOptions.cookies?.storeCookieName || defaultConfig.cookies.storeCookieName;
  const currencyCookieName = moduleOptions.cookies?.currencyCookieName || defaultConfig.cookies.currencyCookieName;
  const localeCookieName = moduleOptions.cookies?.localeCookieName || defaultConfig.cookies.localeCookieName;
  const countryCookieName = moduleOptions.cookies?.countryCookieName || defaultConfig.cookies.countryCookieName;
  const segmentsCookieName = moduleOptions.cookies?.segmentsCookieName || defaultConfig.cookies.segmentsCookieName;
  const wishlistCookieName = moduleOptions.cookies?.wishlistCookieName || defaultConfig.cookies.wishlistCookieName;

  const { setCookie, removeCookie, getCookies } = cookie(req, res);

  const getCartId = () => getCookies(cartCookieName);

  const setCartId = (id) => (!id ? removeCookie(cartCookieName) : setCookie(cartCookieName, id));

  const getCustomerToken = () => getCookies(customerCookieName);

  const setCustomerToken = (token) => (!token ? removeCookie(customerCookieName) : setCookie(customerCookieName, token));

  const getStore = () => getCookies(storeCookieName);

  const setStore = (id) => (!id ? removeCookie(storeCookieName) : setCookie(storeCookieName, id));

  const getCurrency = () => getCookies(currencyCookieName);

  const setCurrency = (id) => (!id ? removeCookie(currencyCookieName) : setCookie(currencyCookieName, id));

  const getLocale = () => getCookies(localeCookieName);

  const setLocale = (id) => (!id ? removeCookie(localeCookieName) : setCookie(localeCookieName, id));

  const getCountry = () => getCookies(countryCookieName);

  const setCountry = (id) => (!id ? removeCookie(countryCookieName) : setCookie(countryCookieName, id));

  const getSegments = (token) => {
    const [checkToken, ids] = (getCookies(segmentsCookieName) || '').split('|');
    return token === checkToken ? ids : [];
  };

  const setSegments = (token, ids) => (
    !ids
      ? removeCookie(segmentsCookieName)
      : setCookie(segmentsCookieName, `${token}|${ids.join(',')}`)
  );

  const setWishlist = (id) => (!id ? removeCookie(wishlistCookieName) : setCookie(wishlistCookieName, id, { maxAge: 60 * 60 * 24 * 365 }));

  const getWishlist = () => getCookies(wishlistCookieName);

  const settings = mapConfigToSetupObject({
    moduleOptions,
    app,
    additionalProperties: {
      state: {
        // Cart
        getCartId,
        setCartId,
        // Customer
        getCustomerToken,
        setCustomerToken,
        // Store
        getStore,
        setStore,
        getCurrency,
        setCurrency,
        getLocale,
        setLocale,
        getCountry,
        setCountry,
        getSegments,
        setSegments,
        getWishlist,
        setWishlist,
      },
    },
  });

  integration.configure('gemini', settings);
});
